import { AddUserSchema, UpdateUserSchema } from '@fastre/core/src/schemas/user'
import { FrontendUserSchema } from '@fastre/core/src/schemas/userMembership'
import { zodResolver } from '@hookform/resolvers/zod'
import EditRoundedIcon from '@mui/icons-material/EditRounded'
import { Autocomplete, Checkbox, FormControl, FormLabel, Option, Select, Switch } from '@mui/joy'
import AspectRatio from '@mui/joy/AspectRatio'
import Box from '@mui/joy/Box'
import Button from '@mui/joy/Button'
import IconButton from '@mui/joy/IconButton'
import Stack from '@mui/joy/Stack'
import { useApi } from 'api'
import { useConjunctionalAgenciesApi, useRolesApi, useUsersApi } from 'apiProviders'
import { useUserData } from 'auth'
import { SlotAutocomplete } from 'components/autocomplete'
import { SlotInput, SlotWrapper } from 'components/input'
import { useShowSnack } from 'components/snackbar'
import { convertFileToBase64 } from 'helperFunctions/file'
import { Maybe } from 'monet'
import { concat, map, prop, propEq } from 'ramda'
import { useRef, useState } from 'react'
import { Controller, SubmitHandler, useForm } from 'react-hook-form'
import { useNavigate } from 'react-router'
import EditAvatarModal from '../editAvatarModal'
import ReiConnection from '../reiConnection'

export default function CoreEditUser({ user: editUser }: { user?: FrontendUserSchema }) {
    const api = useApi()
    const { user: userData } = useUserData()
    const navigate = useNavigate()
    const showSnack = useShowSnack()
    const conjunctionalAgenciesApi = useConjunctionalAgenciesApi()
    const usersApi = useUsersApi()
    const rolesApi = useRolesApi()

    const inputRef = useRef<HTMLInputElement>(null)
    const emailSigInputRef = useRef<HTMLInputElement>(null)

    const [avatarImage, setAvatarImage] = useState<string | undefined>(undefined)
    const [forceRefreshProfileImage, setForceRefreshProfileImage] = useState(Math.random() * 1000)

    const { control, handleSubmit, formState, setValue, watch } = useForm<AddUserSchema>({
        defaultValues: {
            superUser: false,
            salesAgent: false,
            ...(editUser ?? {}),
        },
        resolver: zodResolver(editUser ? UpdateUserSchema : AddUserSchema),
    })

    const conjunctionalAgencyId = watch('conjunctionalAgencyId')
    const conjunctionalAgency = conjunctionalAgenciesApi.maybeData.bind(agencies =>
        Maybe.fromUndefined(agencies.find(agency => agency.conjunctionalAgencyId === conjunctionalAgencyId)),
    )
    const salesAgent = watch('salesAgent')
    const salesAssistantLeadAgentId = watch('salesAssistantLeadAgentId')
    const salesAssistantConjunctionalAgencyId = watch('salesAssistantConjunctionalAgencyId')

    const [salesAssistant, setSalesAssistant] = useState(
        editUser?.salesAssistantLeadAgentId != undefined ||
            editUser?.salesAssistantConjunctionalAgencyId != undefined,
    )

    if (Object.keys(formState.errors).length > 0) {
        console.log('form errors', formState.errors)
    }

    const superUser = watch('superUser')

    const [editAvatarImage, setEditAvatarImage] = useState<string | null>(null)
    const [loading, setLoading] = useState(false)

    const formDisabled = !userData.permissions.includes('users.edit')

    const onSubmit: SubmitHandler<UpdateUserSchema> = async (data: UpdateUserSchema) => {
        setLoading(true)

        const agencyCommission = conjunctionalAgencyId
            ? (await conjunctionalAgenciesApi.promiseData).find(
                  agency => agency.conjunctionalAgencyId === conjunctionalAgencyId,
              )?.agencyCommission ?? 0
            : data.agencyCommission

        try {
            if (editUser) {
                await api.post(`/user/${editUser.userId}/update`, {
                    ...data,
                    agencyCommission,
                })
            } else {
                const newUser = await api
                    .post(`/user/add`, {
                        ...data,
                        avatarImage,
                        agencyCommission,
                    })
                    .then(prop('data'))
                navigate(`/${userData.orgId}/users`)
            }
            usersApi.refresh()
            showSnack(`User ${editUser ? 'updated' : 'added'}`, 'success')
        } catch (e) {
            showSnack(`Error ${editUser ? 'updating' : 'adding'} user`, 'danger')
        } finally {
            setLoading(false)
        }
    }

    return (
        <>
            <form
                noValidate
                onSubmit={handleSubmit(onSubmit)}
            >
                <Box
                    sx={{
                        flex: 1,
                        width: '100%',
                    }}
                >
                    <Stack
                        spacing={4}
                        sx={{
                            display: 'flex',
                            maxWidth: '800px',
                            mx: 'auto',
                            px: {
                                xs: 2,
                                md: 6,
                            },
                            py: {
                                xs: 2,
                                md: 3,
                            },
                        }}
                    >
                        <Stack
                            direction="column"
                            spacing={1}
                        >
                            <Box
                                sx={{
                                    width: 120,
                                    height: 120,
                                    position: 'relative',
                                }}
                            >
                                <AspectRatio
                                    ratio="1"
                                    maxHeight={200}
                                    sx={{
                                        flex: 1,
                                        minWidth: 120,
                                        borderRadius: '100%',
                                    }}
                                >
                                    <img
                                        src={
                                            editUser?.profileImage
                                                ? `${editUser.profileImage}?forceRefreshProfileImage=${forceRefreshProfileImage}`
                                                : ''
                                        }
                                        loading="lazy"
                                        alt=""
                                    />
                                </AspectRatio>
                                <input
                                    type="file"
                                    accept="image/*"
                                    ref={inputRef}
                                    onChange={e => {
                                        if (e?.target?.files && e.target.files[0]) {
                                            setEditAvatarImage(URL.createObjectURL(e.target.files[0]))
                                        }
                                    }}
                                    style={{ display: 'none' }}
                                />
                                <input
                                    type="file"
                                    accept="image/*"
                                    ref={emailSigInputRef}
                                    onChange={async e => {
                                        if (e?.target?.files && e.target.files[0]) {
                                            const b64 = await convertFileToBase64(e.target.files[0])
                                            setValue('emailSignature', b64)
                                        }
                                    }}
                                    style={{ display: 'none' }}
                                />
                                {!formDisabled || userData.userId == editUser?.userId ? (
                                    <IconButton
                                        aria-label="upload new picture"
                                        size="sm"
                                        variant="outlined"
                                        color="neutral"
                                        sx={{
                                            bgcolor: 'background.body',
                                            position: 'absolute',
                                            zIndex: 2,
                                            borderRadius: '50%',
                                            bottom: 0,
                                            right: 0,
                                            boxShadow: 'sm',
                                        }}
                                        onClick={e => {
                                            e.preventDefault()
                                            inputRef.current!.click()
                                        }}
                                    >
                                        <EditRoundedIcon />
                                    </IconButton>
                                ) : null}
                                <IconButton
                                    aria-label="upload new picture"
                                    size="sm"
                                    variant="outlined"
                                    color="neutral"
                                    sx={{
                                        bgcolor: 'background.body',
                                        position: 'absolute',
                                        zIndex: 2,
                                        borderRadius: '50%',
                                        bottom: 0,
                                        right: 0,
                                        boxShadow: 'sm',
                                    }}
                                    onClick={e => {
                                        e.preventDefault()
                                        inputRef.current!.click()
                                    }}
                                >
                                    <EditRoundedIcon />
                                </IconButton>
                            </Box>
                        </Stack>
                        <Stack
                            spacing={2}
                            sx={{ flexGrow: 1 }}
                        >
                            <Stack spacing={1}>
                                <Controller
                                    name="firstName"
                                    control={control}
                                    render={field => (
                                        <SlotInput
                                            label="First Name"
                                            //size="sm"
                                            disabled={editUser != undefined}
                                            {...field}
                                        />
                                    )}
                                />
                                <Controller
                                    name="lastName"
                                    control={control}
                                    render={field => (
                                        <SlotInput
                                            label="Last Name"
                                            //size="sm"
                                            disabled={editUser != undefined}
                                            {...field}
                                        />
                                    )}
                                />
                            </Stack>
                            <Stack
                                direction="row"
                                spacing={2}
                            >
                                <Controller
                                    name="email"
                                    control={control}
                                    render={field => (
                                        <SlotInput
                                            label="Email"
                                            {...field}
                                            disabled={editUser != undefined}
                                        />
                                    )}
                                />
                            </Stack>
                            <Controller
                                name="mobileNumber"
                                control={control}
                                render={field => (
                                    <SlotInput
                                        label="Mobile Number"
                                        disabled={editUser != undefined}
                                        {...field}
                                    />
                                )}
                            />
                            <Controller
                                name="superUser"
                                control={control}
                                render={field => (
                                    <Checkbox
                                        label="Super User"
                                        disabled={!userData.superUser}
                                        onChange={e => {
                                            field.field.onChange(e.target.checked)
                                        }}
                                        checked={field.field.value}
                                    />
                                )}
                            />
                            {!superUser && (
                                <Controller
                                    name="roleId"
                                    control={control}
                                    render={field => (
                                        <SlotWrapper
                                            label="Role"
                                            {...field}
                                        >
                                            <Select
                                                disabled={!userData.permissions.includes('users.edit')}
                                                onChange={(e, value: any) => setValue('roleId', value)}
                                            >
                                                {rolesApi.maybeData.orSome([]).map(role => (
                                                    <Option
                                                        key={role.id}
                                                        value={role.id}
                                                    >
                                                        {role.roleName}
                                                    </Option>
                                                ))}
                                            </Select>
                                        </SlotWrapper>
                                    )}
                                />
                            )}
                            <Controller
                                name="salesAgent"
                                control={control}
                                render={field => (
                                    <Checkbox
                                        label="Sales Agent"
                                        disabled={formDisabled}
                                        onChange={e => {
                                            field.field.onChange(e.target.checked)
                                        }}
                                        checked={field.field.value}
                                    />
                                )}
                            />
                            {salesAgent && (
                                <Controller
                                    name="conjunctionalAgencyId"
                                    control={control}
                                    disabled={formDisabled}
                                    render={field => (
                                        <SlotAutocomplete
                                            {...field}
                                            label="Conjunctional Agency"
                                            onChange={(event, value) => field.field.onChange(value)}
                                            options={conjunctionalAgenciesApi.maybeData
                                                .orSome([])
                                                .map(prop('conjunctionalAgencyId'))}
                                            getOptionLabel={agencyId =>
                                                conjunctionalAgenciesApi.maybeData
                                                    .map(
                                                        agencies =>
                                                            agencies.find(
                                                                agency =>
                                                                    agency.conjunctionalAgencyId === agencyId,
                                                            )?.licenseeName ?? 'Not Found',
                                                    )
                                                    .orSome('loading...')
                                            }
                                        />
                                    )}
                                />
                            )}
                            {salesAgent && (
                                <>
                                    <Controller
                                        name="agencyCommissionBeforeHeadOfficeCommission"
                                        control={control}
                                        disabled={
                                            formDisabled ||
                                            conjunctionalAgencyId != undefined ||
                                            !userData.permissions.includes('users.edit')
                                        }
                                        render={field => (
                                            <Box>
                                                <Switch
                                                    {...field.field}
                                                    checked={field.field.value}
                                                    onChange={e => field.field.onChange(e.target.checked)}
                                                    endDecorator="Agent Commission Before Head Office Commission"
                                                />
                                            </Box>
                                        )}
                                    />
                                    <Controller
                                        name="agencyCommission"
                                        control={control}
                                        disabled={
                                            formDisabled ||
                                            conjunctionalAgencyId != undefined ||
                                            !userData.permissions.includes('users.edit')
                                        }
                                        render={field => (
                                            <SlotInput
                                                {...field}
                                                label="Agent Commission"
                                                type="number"
                                                value={
                                                    conjunctionalAgencyId
                                                        ? conjunctionalAgency
                                                              .map(agency => agency.agencyCommission ?? 0)
                                                              .orSome(0)
                                                        : field.field.value
                                                }
                                            />
                                        )}
                                    />
                                </>
                            )}
                            <Checkbox
                                label="Sales Assistant"
                                disabled={formDisabled}
                                onChange={e => {
                                    setSalesAssistant(e.target.checked)
                                    if (!e.target.checked) {
                                        setValue('salesAssistantLeadAgentId', null)
                                        setValue('salesAssistantConjunctionalAgencyId', null)
                                    }
                                }}
                                checked={salesAssistant}
                            />
                            {salesAssistant && (
                                <FormControl sx={{ flex: 1 }}>
                                    <FormLabel>Assistant For</FormLabel>
                                    <Autocomplete
                                        disabled={formDisabled}
                                        onChange={(event, value) => {
                                            if (value) {
                                                if (value.type == 'user') {
                                                    setValue('salesAssistantLeadAgentId', value.id)
                                                    setValue('salesAssistantConjunctionalAgencyId', null)
                                                } else {
                                                    setValue('salesAssistantConjunctionalAgencyId', value.id)
                                                    setValue('salesAssistantLeadAgentId', null)
                                                }
                                            } else {
                                                setValue('salesAssistantLeadAgentId', null)
                                                setValue('salesAssistantConjunctionalAgencyId', null)
                                            }
                                        }}
                                        value={
                                            salesAssistantLeadAgentId
                                                ? {
                                                      id: salesAssistantLeadAgentId,
                                                      type: 'user',
                                                  }
                                                : salesAssistantConjunctionalAgencyId
                                                  ? {
                                                        id: salesAssistantConjunctionalAgencyId,
                                                        type: 'agency',
                                                    }
                                                  : null
                                        }
                                        options={conjunctionalAgenciesApi.maybeData
                                            .map(
                                                map(x => ({
                                                    id: x.conjunctionalAgencyId,
                                                    type: 'agency',
                                                })),
                                            )
                                            .bind(agencies =>
                                                usersApi.maybeData
                                                    .map(map(x => ({ id: x.userId, type: 'user' })))
                                                    .map(concat(agencies)),
                                            )
                                            .orSome([])}
                                        groupBy={x => (x.type == 'user' ? 'Users' : 'Agencies')}
                                        getOptionLabel={x => {
                                            if (x.type == 'user') {
                                                const foundUser = usersApi.data?.find(propEq('userId', x.id))
                                                return foundUser
                                                    ? `${foundUser.firstName} ${foundUser.lastName}`
                                                    : 'loading...'
                                            } else {
                                                const foundAgency = conjunctionalAgenciesApi.data?.find(
                                                    propEq('conjunctionalAgencyId', x.id),
                                                )
                                                return foundAgency ? foundAgency.licenseeName : 'loading...'
                                            }
                                        }}
                                    />
                                </FormControl>
                            )}
                            <Controller
                                name="emailSignature"
                                control={control}
                                render={({ field }) => (
                                    <FormControl>
                                        <FormLabel>Email Signature</FormLabel>
                                        {field.value && (
                                            <Box>
                                                <img
                                                    src={field.value}
                                                    style={{ maxWidth: '100%' }}
                                                />
                                            </Box>
                                        )}
                                        <Box>
                                            <Button
                                                variant="outlined"
                                                onClick={() => {
                                                    // open upload file dialog
                                                    emailSigInputRef.current!.click()
                                                }}
                                                sx={{ mt: 1 }}
                                            >
                                                {field.value ? 'Change' : 'Upload'}
                                            </Button>
                                        </Box>
                                    </FormControl>
                                )}
                            />
                        </Stack>
                        <Stack
                            direction="row"
                            spacing={2}
                            sx={{
                                justifyContent: 'flex-end',
                                mt: 2,
                            }}
                        >
                            <Button
                                //size="sm"
                                variant="outlined"
                                color="neutral"
                                onClick={() => {
                                    navigate(`/${userData.orgId}/users`)
                                }}
                            >
                                Cancel
                            </Button>
                            <Button
                                //size="sm"
                                variant="solid"
                                type="submit"
                                loading={loading}
                                disabled={formDisabled}
                            >
                                Save
                            </Button>
                        </Stack>
                        {userData.userId == editUser?.userId && <ReiConnection />}
                    </Stack>
                </Box>
            </form>
            <EditAvatarModal
                image={editAvatarImage}
                onClose={() => setEditAvatarImage(null)}
                onSave={async avatarImage => {
                    if (editUser) {
                        await api.post(`/user/${editUser!.userId}/profileimage`, {
                            base64String: avatarImage,
                        })
                        await usersApi.refresh()
                        setForceRefreshProfileImage(forceRefreshProfileImage + 1)
                    } else {
                        setAvatarImage(avatarImage)
                    }
                    setEditAvatarImage(null)
                }}
            />
        </>
    )
}
