import { formatAddress } from '@fastre/core/src/schemas/generic'
import { InternalLedgerSchema, LedgerSchema } from '@fastre/core/src/schemas/ledger'
import { TypesenseListingSchema } from '@fastre/core/src/schemas/typesense'
import { zodResolver } from '@hookform/resolvers/zod'
import { DeleteRounded } from '@mui/icons-material'
import {
    Autocomplete,
    Button,
    DialogActions,
    DialogContent,
    DialogTitle,
    Modal,
    ModalDialog,
    Stack,
} from '@mui/joy'
import { useApi } from 'api'
import AgentInput from 'components/agentInput'
import { SlotInput, SlotWrapper } from 'components/input'
import { dontCloseOnBackgroundClick } from 'components/modal'
import { useShowSnack } from 'components/snackbar'
import { startOfMonth } from 'date-fns'
import { prop } from 'ramda'
import { useEffect, useState } from 'react'
import { Controller, useForm } from 'react-hook-form'
import { useDebounce } from 'use-debounce'
import { v4 as uuid } from 'uuid'
import { useListingContext } from '../listings/listingProvider'

interface ListingAutocompleteProps {
    value?: string
    onChange: (value?: TypesenseListingSchema) => void
    displayValue?: string
    disabled?: boolean
    customFilters?: any[]
}

const ListingAutocomplete = ({
    value,
    onChange,
    displayValue,
    disabled,
    customFilters,
}: ListingAutocompleteProps) => {
    const api = useApi()

    const [loadingListings, setLoadingListings] = useState(false)
    const [listingOptions, setListingOptions] = useState<TypesenseListingSchema[]>([])
    const [listingSearch, setListingSearch] = useState<string>('')
    const [debouncedListingSearch] = useDebounce(listingSearch, 200)

    useEffect(() => {
        console.log('searching', customFilters)
        setLoadingListings(true)
        const controller = new AbortController()
        api.post(
            '/listings/sale/search',
            {
                search: debouncedListingSearch,
                limit: 10,
                customFilters,
            },
            {
                signal: controller.signal,
            },
        ).then(({ data }) => {
            setListingOptions(data.hits.map(prop('document')))
            setLoadingListings(false)
        })

        return () => controller.abort()
    }, [debouncedListingSearch, JSON.stringify(customFilters)])

    return (
        <Autocomplete
            value={displayValue ?? null}
            options={(listingOptions ?? []) as Array<TypesenseListingSchema | string>}
            loading={loadingListings}
            getOptionLabel={option =>
                typeof option === 'string' ? option : formatAddress(option.listingAddress)
            }
            onChange={(e, value) => onChange((value as any) ?? undefined)}
            onChangeCapture={async (e: any) => setListingSearch(e.target.value)}
            onBlur={() => setListingSearch('')}
            disabled={disabled}
        />
    )
}

interface EditRowModalProps {
    row: Partial<InternalLedgerSchema> | undefined
    onClose: (updated: boolean) => void
}

export default function EditLedgerItemModal({ row, onClose }: EditRowModalProps) {
    const api = useApi()
    const listingContext = useListingContext()
    const showSnack = useShowSnack()
    const { handleSubmit, watch, setValue, formState, control, setError, clearErrors, reset } =
        useForm<LedgerSchema>({
            defaultValues: {},
            resolver: zodResolver(LedgerSchema),
        })

    useEffect(() => {
        reset({
            id: row?.id ?? uuid(),
            listingId: listingContext?.listing.listingId,
            listingName: listingContext ? formatAddress(listingContext.listing.listingAddress) : undefined,
            ...row,
        })
    }, [row])

    const listingName = watch('listingName')
    const userId = watch('userId')
    const ledgerDate = watch('ledgerDate')

    const [ledgerApproved, setLedgerApproved] = useState<boolean>()

    if (Object.keys(formState.errors).length > 0) {
        console.log(formState.errors)
    }

    useEffect(() => {
        if (ledgerApproved === true) {
            setError(
                'userId',
                { message: 'Ledger is already approved for this month' },
                { shouldFocus: false },
            )
        }
        if (ledgerApproved === false) {
            clearErrors('userId')
        }
    }, [ledgerApproved])

    useEffect(() => {
        if (userId) {
            setLedgerApproved(undefined)
            api.post('/ledger/isapproved', [{ userId, month: ledgerDate }]).then(({ data }) =>
                setLedgerApproved(data[0]?.approved == true),
            )
        }
    }, [userId, startOfMonth(ledgerDate).getTime()])

    if (Object.keys(formState.errors).length > 0) {
        console.log(formState.errors)
    }

    const [loading, setLoading] = useState(false)

    const onSubmit = async (data: LedgerSchema) => {
        setLoading(true)
        try {
            await api.post('/ledger/create', data)
            showSnack('Ledger item created', 'success')
            onClose(true)
        } catch (e: any) {
            console.error(e)
            showSnack(e?.response?.data?.details ?? 'Error creating ledger item', 'danger')
        } finally {
            setLoading(false)
        }
    }

    return (
        <Modal
            open={row != undefined}
            onClose={dontCloseOnBackgroundClick(() => onClose(false))}
        >
            <ModalDialog>
                <DialogTitle>{row?.id != undefined ? 'Edit' : 'Create'} Ledger</DialogTitle>
                <DialogContent sx={{ minWidth: '350px' }}>
                    {row && (
                        <form noValidate>
                            <Stack spacing={2}>
                                <Controller
                                    name="listingId"
                                    control={control}
                                    render={field => (
                                        <SlotWrapper
                                            label="Listing"
                                            {...field}
                                        >
                                            <ListingAutocomplete
                                                value={field.field.value}
                                                displayValue={listingName}
                                                onChange={value => {
                                                    setValue('listingId', value?.listingId)
                                                    setValue(
                                                        'listingName',
                                                        value
                                                            ? formatAddress(value.listingAddress)
                                                            : undefined,
                                                    )
                                                    if (value) {
                                                        setValue('userId', value.agentUserId)
                                                    }
                                                }}
                                                customFilters={userId ? [`agentUserId:${userId}`] : undefined}
                                            />
                                        </SlotWrapper>
                                    )}
                                />
                                <Controller
                                    name="userId"
                                    control={control}
                                    render={field => (
                                        <SlotWrapper
                                            {...field}
                                            label="Agent*"
                                        >
                                            <AgentInput
                                                {...field.field}
                                                value={field.field.value}
                                                onChange={agentId => {
                                                    field.field.onChange(agentId)
                                                    setValue('listingId', undefined)
                                                    setValue('listingName', undefined)
                                                }}
                                                //disabled={row?.userId != undefined}
                                            />
                                        </SlotWrapper>
                                    )}
                                />
                                <Controller
                                    name="ledgerDate"
                                    control={control}
                                    render={field => (
                                        <SlotInput
                                            label="Date*"
                                            type="date"
                                            {...field}
                                        />
                                    )}
                                />
                                <Controller
                                    name="supplier"
                                    control={control}
                                    render={field => (
                                        <SlotInput
                                            label="Supplier*"
                                            {...field}
                                        />
                                    )}
                                />
                                <Controller
                                    name="invoiceNumber"
                                    control={control}
                                    render={field => (
                                        <SlotInput
                                            label="Invoice Number*"
                                            {...field}
                                        />
                                    )}
                                />
                                <Controller
                                    name="description"
                                    control={control}
                                    render={field => (
                                        <SlotInput
                                            label="Description*"
                                            {...field}
                                        />
                                    )}
                                />
                                <Controller
                                    name="debit"
                                    control={control}
                                    render={field => (
                                        <SlotInput
                                            label="Debit"
                                            type="dollar"
                                            {...field}
                                        />
                                    )}
                                />
                                <Controller
                                    name="credit"
                                    control={control}
                                    render={field => (
                                        <SlotInput
                                            label="Credit"
                                            type="dollar"
                                            {...field}
                                        />
                                    )}
                                />
                            </Stack>
                        </form>
                    )}
                </DialogContent>
                <DialogActions>
                    <Button
                        loading={loading}
                        onClick={handleSubmit(onSubmit)}
                    >
                        Save
                    </Button>
                    <Button
                        variant="outlined"
                        onClick={() => onClose(false)}
                    >
                        Cancel
                    </Button>
                    {row?.id && (
                        <Button
                            variant="outlined"
                            color="danger"
                            loading={loading}
                            startDecorator={<DeleteRounded />}
                            onClick={async () => {
                                setLoading(true)
                                await api.delete(`/ledger/${row!.id}`)
                                setLoading(false)
                                onClose(true)
                            }}
                            sx={{ mr: 'auto' }}
                        >
                            Delete
                        </Button>
                    )}
                </DialogActions>
            </ModalDialog>
        </Modal>
    )
}
