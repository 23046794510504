import {
    Box,
    Button,
    Checkbox,
    DialogActions,
    DialogContent,
    DialogTitle,
    Modal,
    ModalClose,
    ModalDialog,
    Stack,
    Typography,
} from '@mui/joy'
import { useApi } from 'api'
import { useListingFilesApi, useListingType } from 'apiProviders'
import Loading from 'components/Loading'
import { dontCloseOnBackgroundClick } from 'components/modal'
import { useShowSnack } from 'components/snackbar'
import { useOrgId } from 'helpers'
import { useListingContext } from 'listings/listingProvider'
import { prop } from 'ramda'
import { useState } from 'react'

interface Props {
    open: boolean
    onClose: () => void
    setEditUrl: (url: string) => void
}

export default function AddAttachmentModal({ open, onClose, setEditUrl }: Props) {
    const api = useApi()
    const { listing, setListing } = useListingContext()
    const showSnack = useShowSnack()
    const orgId = useOrgId()
    const listingType = useListingType()
    const filesApi = useListingFilesApi({ excludeFolders: true })

    const [s3Keys, sets3Keys] = useState<string[]>([])
    const [loading, setLoading] = useState(false)

    const locationRoot = `${orgId}/${listingType}/${listing.listingId}/files/`

    return (
        <Modal
            open={open}
            onClose={dontCloseOnBackgroundClick(onClose)}
        >
            <ModalDialog
                sx={{
                    minWidth: 500,
                    minHeight: 450,
                }}
            >
                <ModalClose />
                <DialogTitle>Add Attachments</DialogTitle>
                <DialogContent>
                    <Box sx={{ px: 1, mt: 1 }}>
                        <Stack gap={1}>
                            {listing.propertyType == 'cts' && (
                                <Checkbox
                                    checked={true}
                                    label="Disclosure Statement"
                                    disabled={true}
                                />
                            )}
                            {filesApi?.data?.length == 0 && (
                                <Typography sx={{ mt: 2 }}>No PDF files uploaded to listing</Typography>
                            )}
                            {filesApi.data?.map(file => (
                                <Checkbox
                                    key={file.Key}
                                    checked={s3Keys.includes(file.Key)}
                                    onChange={e => {
                                        if (e.target.checked) {
                                            sets3Keys([...s3Keys, file.Key])
                                        } else {
                                            sets3Keys(s3Keys.filter(x => x != file.Key))
                                        }
                                    }}
                                    label={file.Key.replace(locationRoot, '')}
                                />
                            ))}
                        </Stack>
                        {filesApi.data == undefined && <Loading />}
                    </Box>
                </DialogContent>
                <DialogActions>
                    <Button
                        loading={loading}
                        onClick={async () => {
                            setLoading(true)
                            try {
                                const { updatedListing, docusignUrl } = await api
                                    .post(
                                        `/listing/${listingType}/${listing.listingId}/docusign/contractform/create`,
                                        {
                                            s3Keys,
                                            returnUrl: `${window.location.origin}/docusign/close`,
                                        },
                                    )
                                    .then(prop('data'))
                                setListing(updatedListing)
                                setEditUrl(docusignUrl)
                                onClose()
                            } catch (e) {
                                showSnack('Error uploading to docusign', 'danger')
                            }
                            setLoading(false)
                        }}
                        disabled={loading}
                    >
                        Create
                    </Button>
                    <Button
                        variant="outlined"
                        onClick={onClose}
                    >
                        Cancel
                    </Button>
                </DialogActions>
            </ModalDialog>
        </Modal>
    )
}
