import { toCurrency } from '@fastre/core/src/helperFunctions/string'
import { Close, DoneRounded, ErrorRounded } from '@mui/icons-material'
import { Box, Button, Link, Table, Typography } from '@mui/joy'
import { useApi } from 'api'
import { useBulkLedgerApi, useUsersApi } from 'apiProviders'
import { useUserData } from 'auth'
import MonthSelector from 'components/ledgerMonthSelector'
import Loading from 'components/Loading'
import SectionHead from 'components/sectionHead'
import { useOrgId } from 'helpers'
import { propEq, sum } from 'ramda'
import { useState } from 'react'
import { useNavigate } from 'react-router'
import EditLedgerItemModal from './editLedgerItemModal'

export default function LedgersView() {
    const api = useApi()
    const orgId = useOrgId()
    const usersApi = useUsersApi()
    const navigate = useNavigate()
    const bulkLedgerApi = useBulkLedgerApi()
    const { user } = useUserData()

    const [openLoading, setOpenLoading] = useState(false)
    const [addLedgerItem, setAddLedgerItem] = useState(false)

    return (
        <Box
            sx={{
                px: {
                    xs: 2,
                    sm: 4,
                },
            }}
        >
            <SectionHead
                title="Ledger Manager"
                buttons={
                    <Button
                        onClick={() => navigate('bulkupload')}
                        variant="outlined"
                    >
                        Bulk Upload
                    </Button>
                }
            />
            <MonthSelector />

            {bulkLedgerApi.loading ? (
                <Loading />
            ) : (
                <>
                    <Box sx={{ my: 2, display: 'flex', gap: 2, alignItems: 'center', height: 32 }}>
                        <Typography>
                            {bulkLedgerApi.data?.open ? 'Ledger Approvals Open' : 'Ledger Approvals Closed'}
                        </Typography>
                        {!bulkLedgerApi.loading && (
                            <>
                                {user.permissions.includes('ledger.approve') &&
                                    bulkLedgerApi.data?.open === false && (
                                        <Button
                                            variant="outlined"
                                            size="sm"
                                            loading={openLoading}
                                            onClick={async () => {
                                                setOpenLoading(true)
                                                await api.post(`/ledger/open`, {
                                                    month: bulkLedgerApi.params.month,
                                                })
                                                bulkLedgerApi.setVals(x => ({ ...x, open: true }))
                                                setOpenLoading(false)
                                            }}
                                        >
                                            Open Ledger for Approval
                                        </Button>
                                    )}
                            </>
                        )}
                    </Box>
                    {user.permissions.includes('ledger.edit') && (
                        <Button
                            variant="outlined"
                            size="sm"
                            onClick={() => setAddLedgerItem(true)}
                            sx={{
                                mb: 2,
                            }}
                        >
                            Add Ledger Entry
                        </Button>
                    )}
                    <EditLedgerItemModal
                        row={
                            addLedgerItem
                                ? {
                                      ledgerDate: bulkLedgerApi.params.month,
                                  }
                                : undefined
                        }
                        onClose={() => setAddLedgerItem(false)}
                    />

                    <Table>
                        <thead>
                            <tr>
                                <th>User</th>
                                <th style={{ textAlign: 'right' }}>Amount</th>
                                <th style={{ textAlign: 'center' }}>Disputes</th>
                                <th style={{ textAlign: 'center' }}>Approved</th>
                                <th style={{ textAlign: 'center' }}>Invoiced/Agent Statement</th>
                            </tr>
                        </thead>
                        <tbody>
                            {usersApi.data
                                ?.filter(user => user.salesAgent)
                                .map(user => {
                                    const items = bulkLedgerApi.data?.items.filter(
                                        propEq('userId', user.userId),
                                    )
                                    const approval = bulkLedgerApi.data?.approvals.find(
                                        propEq('userId', user.userId),
                                    )
                                    const disputes =
                                        items?.filter(x => x.disputeReason != undefined).length ?? 0

                                    return (
                                        <tr key={user.userId}>
                                            <td>
                                                <Link
                                                    href={`/${orgId}/users/${user.userId}?tab=Ledger&month=${bulkLedgerApi.params.month}`}
                                                >
                                                    {user.firstName} {user.lastName}
                                                </Link>
                                            </td>
                                            <td style={{ textAlign: 'right' }}>
                                                {toCurrency(
                                                    sum(
                                                        items?.map(x => (x.credit ?? 0) - (x.debit ?? 0)) ?? [
                                                            0,
                                                        ],
                                                    ),
                                                )}
                                            </td>
                                            <td style={{ textAlign: 'center' }}>
                                                {disputes > 0 && (
                                                    <>
                                                        <ErrorRounded
                                                            color={'danger' as any}
                                                            fontSize={'lg' as any}
                                                        />
                                                    </>
                                                )}
                                            </td>
                                            <td style={{ textAlign: 'center' }}>
                                                {approval ? (
                                                    <DoneRounded
                                                        color="success"
                                                        fontSize={'lg' as any}
                                                    />
                                                ) : (
                                                    <Close
                                                        color={'danger' as any}
                                                        fontSize={'lg' as any}
                                                    />
                                                )}
                                            </td>
                                            <td style={{ textAlign: 'center' }}>
                                                {approval?.xeroInvoice ||
                                                approval?.creditMovedForwards ||
                                                approval?.agentStatementAdded ? (
                                                    <DoneRounded
                                                        color="success"
                                                        fontSize={'lg' as any}
                                                    />
                                                ) : (
                                                    <Close
                                                        color={'danger' as any}
                                                        fontSize={'lg' as any}
                                                    />
                                                )}
                                            </td>
                                        </tr>
                                    )
                                })}
                        </tbody>
                    </Table>
                </>
            )}
        </Box>
    )
}
