import { addDays, format, setYear } from 'date-fns'
import { pick } from 'ramda'
import { CollectionFieldSchema } from 'typesense/lib/Typesense/Collection'
import z from 'zod'
import { reduceToObject } from '../helperFunctions/array'
import { injectIf } from '../helperFunctions/object'
import { getSettlementDate } from './contract'
import { formatAddress } from './generic'
import { ListingStatuses } from './listing'
import { InternalSaleListingSchema } from './saleListing'
import { UserTableItemSchema } from './user'

const typesenseListingProps: readonly [
    keyof InternalSaleListingSchema,
    ...(keyof InternalSaleListingSchema)[],
] = [
    'orgId',
    'listingId',
    'additionalDetails',
    'listingAddress',
    'advertisedPrice',
    //'authorityStartDate',
    'authorityPeriod',
    'hiddenPrice',
    'listingStatus',
    'propertyInfo',
    'vendors',
    'geoLocation',
    'agentUserId',
    'agentAppointmentForm',
    //'contract',
    'contractForm',
    'disclosureStatementForm',
    'commission',
    'statusLog',
]

export const transformListingForTypesense =
    (users: UserTableItemSchema[]) => (listing: InternalSaleListingSchema) => {
        const user = users.find(user => user.userId == listing.agentUserId)

        //console.log('TS transform user', user)

        const settlementDate = getSettlementDate(listing.contract)

        return {
            id: listing.listingId,
            geoLocation: [0, 0],
            authorityEndDate: listing.authorityStartDate
                ? addDays(new Date(listing.authorityStartDate), listing.authorityPeriod! - 1).getTime()
                : undefined,
            ...pick(typesenseListingProps, listing),
            addressString: formatAddress(listing.listingAddress, 'full'),
            ...injectIf(user != undefined, {
                userNames: `${user?.firstName} ${user?.lastName}`,
            }),
            authorityStartDate: listing.authorityStartDate
                ? new Date(listing.authorityStartDate).getTime()
                : undefined,
            contract: listing.contract
                ? {
                      ...listing.contract,
                      contractDate: listing.contract.contractDate
                          ? new Date(listing.contract.contractDate).getTime()
                          : undefined,
                  }
                : undefined,
            settlementDate: settlementDate?.getTime(),
            ...ListingStatuses.map(status => ({
                [`statusLog-${status}`]: listing.statusLog[status],
            })).reduce(reduceToObject, {}),
            settlementMonthDay: settlementDate ? format(settlementDate, 'MM-dd') : undefined,
            settlementMonth: settlementDate ? format(settlementDate, 'MM') : undefined,
            settlementDate1970: settlementDate ? setYear(settlementDate, 1970).getTime() : undefined,
            contractDate:
                listing.contract?.contractDate != undefined
                    ? new Date(listing.contract.contractDate).getTime()
                    : undefined,
        }
    }

export type TypesenseListingSchema = ReturnType<ReturnType<typeof transformListingForTypesense>> & {
    userNames: string
}

export const typesenseFields: CollectionFieldSchema[] = [
    {
        name: 'orgId',
        type: 'string',
        index: true,
    },
    {
        name: 'listingId',
        type: 'string',
    },
    {
        name: 'additionalDetails',
        type: 'object',
        optional: true,
    },
    {
        name: 'geoLocation',
        type: 'geopoint',
    },
    {
        name: 'listingAddress',
        type: 'object',
    },
    {
        name: 'addressString',
        type: 'string',
        sort: true,
    },
    {
        name: 'advertisedPrice',
        type: 'string',
        optional: true,
        sort: true,
    },
    {
        name: 'authorityStartDate',
        type: 'int64',
        optional: true,
    },
    {
        name: 'authorityEndDate',
        type: 'int64',
        optional: true,
        sort: true,
    },
    {
        name: 'hiddenPrice',
        type: 'object',
        optional: true,
    },
    {
        name: 'listingStatus',
        type: 'string',
        facet: true,
        sort: true,
    },
    {
        name: 'propertyInfo',
        type: 'object',
        optional: true,
    },
    {
        name: 'vendors',
        type: 'object[]',
        optional: true,
    },
    {
        name: 'agentUserId',
        type: 'string',
        facet: true,
        sort: true,
    },
    {
        name: 'userNames',
        type: 'string',
        sort: true,
    },
    {
        name: 'contract',
        type: 'object',
        optional: true,
    },
    {
        name: 'contract.contractDate',
        type: 'int64',
        optional: true,
        sort: true,
    },
    {
        name: 'contract.contractPrice',
        type: 'int32',
        optional: true,
    },
    {
        name: 'contract.commissionOverrideValue',
        type: 'float',
        optional: true,
    },
    {
        name: 'contractDate',
        type: 'int64',
        optional: true,
    },
    {
        name: 'settlementDate',
        type: 'int64',
        optional: true,
        range_index: true,
    },
    { name: 'settlementDate1970', type: 'int64', optional: true },
    {
        name: 'settlementMonthDay',
        type: 'string',
        optional: true,
    },
    {
        name: 'settlementMonth',
        type: 'string',
        optional: true,
    },
    {
        name: 'statusLog-offline',
        type: 'int64',
        optional: true,
    },
    {
        name: 'statusLog-available',
        type: 'int64',
        optional: true,
    },
    {
        name: 'statusLog-under contract',
        type: 'int64',
        optional: true,
    },
    {
        name: 'statusLog-unconditional',
        type: 'int64',
        optional: true,
    },
    {
        name: 'statusLog-sold',
        type: 'int64',
        optional: true,
    },
    {
        name: 'statusLog-withdrawn',
        type: 'int64',
        optional: true,
    },
    {
        name: 'marketingPaymentStatus',
        type: 'object',
        optional: true,
        fields: [{ name: 'paymentStatus', type: 'string' }],
    },
]

export interface Facet {
    value: string
    count: number
    highlighted: string
}

export const SaleListingSearchSchema = z.object({
    search: z.string().optional().default(''),
    limit: z.number().max(250).optional().default(250),
    page: z.number().optional().default(1),
    status: z.string().array().optional(),
    agentIds: z.string().array().optional(),
    sort: z.string().optional(),
    sortDirection: z.enum(['asc', 'desc']).optional().default('desc'),
    actionFilters: z.record(z.string().array()).optional(),
    customFilters: z.array(z.string()).optional(),
})

export type SaleListingSearchSchema = z.infer<typeof SaleListingSearchSchema>
