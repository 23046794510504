import { Route, Routes } from 'react-router'
import BulkUploadLedger from './bulkUploadLedger'
import LedgersView from './ledgersView'

export default function ManageLedgers() {
    return (
        <Routes>
            <Route
                path="bulkupload"
                element={<BulkUploadLedger />}
            />
            <Route
                index
                element={<LedgersView />}
            />
        </Routes>
    )
}
