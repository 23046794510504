import z from 'zod'

export const UpdateUserSchema = z
    .object({
        firstName: z.string().min(1),
        lastName: z.string().min(1),
        conjunctionalAgencyId: z.string().nullable().optional(),
        agencyCommission: z.number().min(0).max(100).default(0),
        agencyCommissionBeforeHeadOfficeCommission: z.boolean(),
        salesAgent: z.boolean(),
        salesAssistantConjunctionalAgencyId: z.string().nullable().optional(),
        salesAssistantLeadAgentId: z.string().nullable().optional(),
        emailSignature: z.string().optional(),
    })
    .and(
        z.union([
            z.object({
                superUser: z.literal(false),
                roleId: z.string(),
            }),
            z.object({
                superUser: z.literal(true),
            }),
        ]),
    )

export const AddUserSchema = UpdateUserSchema.and(
    z.object({
        email: z.string().email(),
        mobileNumber: z.string().optional(),
        avatarImage: z.string().optional(),
    }),
)

export type AddUserSchema = z.infer<typeof AddUserSchema>
export type UpdateUserSchema = z.infer<typeof UpdateUserSchema>

export interface InternalUserSchema
    extends Omit<
        AddUserSchema,
        | 'profileImage'
        | 'conjunctionalAgencyId'
        | 'roleId'
        | 'superUser'
        | 'salesAgent'
        | 'agencyCommission'
        | 'salesAssistantConjunctionalAgencyId'
        | 'salesAssistantLeadAgentId'
    > {
    userId: string
    //profileImage?: string
    //conjunctionalAgencyId?: string
    reiConnectionToken?: string
    firstName: string
    lastName: string
    expoPushTokens?: string[]
    roleId: string
    roleName: string
}

export interface UserTableItemSchema {
    userId: string
    email: string
    mobileNumber: string
    lastLoggedIn: number
    profileImage: string
    firstName: string
    lastName: string
}
